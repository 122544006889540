import React from 'react'
import Title from '../Text/Title'
import SubTitle from '../Text/SubTitle';
import { motion } from "framer-motion";
import  './about.scss'

const About = () => {

    const animate = {
        hidden: {
            scale: 0.5,
            opacity: 0,
            // y: 10,
            transition: {
                type: 'scale',
                stiffness: 300,
                damping: 140,
            },
        },
        show: {
            scale: 1,
            opacity: 1,
            // y: 0,
            transition: {
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
            }
        },
    };

    return (
        <motion.div
        id='about' className='about'>
            <motion.div
            variants={animate}
            initial="hidden"
            whileInView="show"
            // viewport={{amout: 0.1, once: true,}}
            className='about__title'>
                <Title  about='ПРО НАС'/>
            </motion.div>
            <motion.div
            variants={animate}
            initial="hidden"
            whileInView="show"
            // viewport={{amout: 0.1, once: true,}}
            className='about__subtitle'>
                <SubTitle  about='Ми - ТОВ «БК С.А.В.І.» пропонуємо свої послуги у сфері будівництва. Маємо багаторічний досвід в напрямку будівельно-монтажних робіт та штат досвідчених спеціалістів. Забезпечимо виконання робіт в зазначені терміни. Даємо гарантію на виконані роботи. Допоможемо обрати найбільш ефективне та економне проектне рішення. Завдяки партнерським відносинам із постачальниками, забезпечимо поставку матеріалів по цінах із суттєвою знижкою.'/>
            </motion.div>
        </motion.div>
    )
}

export default About
